import { createApp } from "vue/dist/vue.esm-bundler";
import App from "@/App.vue";

import axios from "axios";
import VueAxios from "vue-axios";

import store from "@/store";
import router from "@/router";

import Echo from "laravel-echo";
import Pusher from "pusher-js";

import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

import JsonViewer from "vue3-json-viewer";

import BaseLoader from "@/components/UI/BaseLoader.vue";
import ViewButton from "@/components/UI/ViewButton.vue";

window.slert = (text) => {
  store.dispatch("ui/setAlert", {
    type: "success",
    text: text,
  });
};
window.elert = (text) => {
  store.dispatch("ui/setAlert", {
    type: "error",
    text: text,
  });
};
window.catchSample = (e) => {
  if (e.response.status === 500 || e.response.status === 404) {
    if (e.response.status === 500) {
      store.dispatch("ui/setAlert", {
        type: "error",
        text: "Внутрішня помилка сервера",
      });
      return true;
    }else{
      store.dispatch("ui/setAlert", {
        type: "error",
        text: "Ресурс не знайдено",
      });
      return true;
    }
    
  } else {
    if (e.response.status === 401) {
      store.dispatch("ui/setAlert", {
        type: "error",
        text: "Потрібна авторизація",
      });
        store.dispatch("auth/signOut");
      
    } else if (e.response.status === 403) {
      store.dispatch("ui/setAlert", {
        type: "error",
        text: "Доступ заборонено",
      });
    } else if (e.response.status === 419) {
      store.dispatch("ui/setAlert", {
        type: "error",
        text: "Сесія застаріла",
      });
      store.dispatch("auth/signOut");
    } else if (e.response.status === 422) {
      store.dispatch("ui/setAlert", {
        type: "error",
        text: e.response.data.errors,
      });
    } else {
      store.dispatch("ui/setAlert", {
        type: "error",
        text: e.response.data.errors,
      });
    }
    return true;
  }
};

const app = createApp(App);

app.component("BaseLoader", BaseLoader);
app.component("ViewButton", ViewButton);

app.use(store);
app.use(router);

router.app = app;
app.use(VueAxios, axios);
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let pusherKey = "9abd41ba5a825029fa45";
let secretKey = "ec80926d20561e15558b";
if(process.env.NODE_ENV === 'development'){
  axios.defaults.baseURL = "https://dev-ag.dshub.org";
}
if(process.env.NODE_ENV === 'production'){
  axios.defaults.baseURL = "https://api-ag.dshub.org"; 
  pusherKey = "234b4e0de40bf4bf633e";
  secretKey = "11019e45e07af1df557a";
  
  // axios.defaults.baseURL = "https://api.agapit.in.ua"; 
  // pusherKey = "aaa1b71afd67af0d286e";
  // secretKey = "5406f87e1afcdfe0efad";
}


window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: "pusher",
  key: pusherKey,
  secret: secretKey,
  cluster: "eu",
  forceTLS: true,
  encrypted: true,
  authorizer: (channel) => ({
    authorize: (socketId, callback) => {
      axios
        .post("/api/broadcasting/auth", {
          socket_id: socketId,
          channel_name: channel.name,
        })
        .then((response) => {
          callback(false, response.data);
        })
        .catch((error) => {
          callback(true, error);
        });
    },
  }),
});

app.use(FloatingVue);
app.use(JsonViewer);

app.mount("#app");
